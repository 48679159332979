<template>
  <div class="attr-item">
    <CompPickerLink v-model='localValue' size="small" @change="handleChange" />
    <el-select class="input-m" v-model="localValue.rule_id" placeholder="请选择" filterable remote clearable
      v-if="localValue && localValue?.id == 'drawer-shop'" size="small" @change="handleRuleIdChange">
      <el-option v-for="item in list" :key="item.rule_id" :label="item.rule_title" :value="item.rule_id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import CompPickerLink from "../../comps/comp-pickerLink";
import { cloneDeep } from 'lodash'
import { getShowRuleList } from '@/api/template'

export default {
  components: { CompPickerLink },
  props: {
    uuid: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  watch: {
    uuid: {
      immediate: true,
      handler(newVal) {
        this.localValue = cloneDeep(this.value)
        if (this.value.id == "drawer-shop") {
          this.getList()
        }
      }
    },
    localValue: {
      deep: true,
      handler(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  data() {
    return {
      localValue: null,
      list: []
    }
  },
  methods: {
    handleChange(e) {
      const { id } = e
      if (id == "drawer-shop") {
        this.getList()
      }else{
        this.$set(this.localValue, 'rule_id', '')
      }
    },
    async getList() {
      const { template_douyin = 0 } = this.$route.query
      const res = await getShowRuleList({
        page: 1,
        page_size: -1,
        platform: template_douyin == 1 ? 'byte_mini' : 'wxapp',
      })
      this.list = [{ rule_id: '0', rule_title: '全部门店' }, ...res.data.data.list]
    },
    handleRuleIdChange(id) {
      let item = this.list.find(item => item.rule_id == id)
      this.localValue.distributor_header_image = item.distributor_header_image || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.attr-item{
  display: flex;
  align-items: flex-end
}
.input-m {
  margin-left: 15px;
  width: 150px;
}
</style>
