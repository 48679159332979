<style lang="scss" scoped>
.attr_slider {
  margin-left: -80px;

  .slider-item {
    display: flex;
    border-top: 1px solid #fff;
    padding-top: 10px;
    justify-content: space-between;
    width: 100%;

    .slider-item-img {
      display: flex;
    }

    .slider-item-info {
      .slider-item-type {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .el-radio-group {
          margin-top: 0px;
        }

        .label {
          width: 50px;
        }
      }
    }

    .slider-item-link {
      display: flex;

      .label {
        width: 50px;
        flex-shrink: 0;
      }

      .zone-item {
        display: flex;
        flex-direction: column;

      }
    }
  }
}
</style>
<style lang="scss">
.attr_slider {
  .drag-list {
    background-color: #c2d2f5;
    padding: 10px;
  }

  .btn-todo {
    width: 100%;
  }

  .slider-item-link {
    .zone-item {
      .zone-item-link {

        .btn-linkpath {
          border: none;
          background-color: rgba($color: #000000, $alpha: 0);
          line-height: 24px;
          height: 24px;
          padding: 0px 3px 0 0;
        }
      }
    }
  }
}

.attr_slider_dialog {
  .el-dialog__body {
    max-height: 72vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
</style>
<template>
  <div class="attr_slider" v-if="refresh">
    <CompDragList v-model="localValue" @onAddItem="handleClickAdd" type="text" btnText="添加轮播项" :show-title="true"
      title="轮播项" @move="handleMove">
      <template slot="body" slot-scope="scope">
        <div class="slider-item">
          <div class="slider-item-info">
            <div class="slider-item-type">
              <p class="label">
                模式
              </p>
              <el-radio-group size="mini" v-model="scope.data.media_type"
                @change="handleChangeType(scope.index, $event)">
                <el-radio-button label="img">图片</el-radio-button>
                <el-radio-button label="video">视频</el-radio-button>
              </el-radio-group>
            </div>
            <div class="slider-item-link">
              <p class="label">
                链接
              </p>
              <div>
                <div class="zone-item" v-if="scope.data.media_type === 'img' && scope.data.pic_type != 'hot'">
                  <span class="zone-item-link">
                    <CompHotPicker :isShowH5Link="false" :value="scope.data.data" wgtType="hotzone"
                      @change="(e) => onChangeLocalData(e, scope.index)" />
                  </span>
                </div>
                <div class="zone-item" v-if="scope.data.media_type === 'img' && scope.data.pic_type == 'hot'">
                  <span v-for="(item, index) in scope.data.hotData" :key="`copy-zone-item__${index}`"
                    class="zone-item-link">
                    <CompHotPicker :isShowH5Link="false" :value="item" wgtType="hotzone"
                      @change="(e) => onChangeLocalhotDate(e, scope.index, index)" />
                  </span>
                </div>
                <div class="zone-item">
                  <span v-for="(item, index) in scope.data.overlayHotData" :key="`copy-zone-item__${index}`"
                    class="zone-item-link">
                    <CompHotPicker :isShowH5Link="false" :value="item" wgtType="hotzone"
                      @change="(e) => onChangeLocaloverhotDate(e, scope.index, index)" />
                  </span>
                </div>
              </div>
            </div>
            <div class="slider-item-link" v-if="comTypes">
              <p class="label">
                智能ID
              </p>
              <el-input v-model="scope.data.aiId"/>
            </div>
          </div>
          <div class="slider-item-img">
            <sp-image :src="scope.data.imgUrl" width="70" height="70"
              @click.native="onSetHotZone(scope.data, scope.index)" />
          </div>
        </div>
      </template>
    </CompDragList>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import CompDragList from '../../comps/comp-dragList'
import CompButton from '../../comps/comp-button'
import CompHotPicker from '../../comps/comp-hotpicker'
import CompPickerLink from '../../comps/comp-pickerLink'
import SliderDialog from './sliderDialog.vue'
import { getImageAttribute, getVideoAttribute } from "./utils";
import { useDialog } from '@shopex/finder'

export default {
  name: 'AttrItem',
  components: {
    CompButton,
    CompDragList,
    CompPickerLink,
    CompHotPicker,
    SliderDialog
  },
  props: {
    value: {
      type: Array
    },
    direction: {
      type: String
    },
    uuid: {
      type: String
    },
    proportion: Number,
    backColor: String,
    height: Number,
    comTypes:String
  },
  data() {
    return {
      localValue: [],
      dialog: false,
      currentIndex: 0,
      sliderForm: null,
      refresh: true
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler: function (nVal, oVal) {
        this.$emit('input', nVal)
      }
    },
    uuid: {
      handler(nVal, oVal) {
        this.localValue = cloneDeep(this.value)
      },
      deep: true
    }
  },
  created() {
    this.localValue = cloneDeep(this.value)
  },
  methods: {
    async onSetHotZone(item, index) {
      useDialog(null, {
        title: `轮播项${index + 1}`,
        width: '860px',
        top: '4vh',
        class: 'attr_slider_dialog',
        sliderItem: item,
        proportion: this.proportion,
        defaultValue: this.localValue[0],
        index: index,
        height: this.height,
        component: () => import('./sliderDialog.vue'),
        actions: [
          {
            label: '关闭',
            key: 'close',
            type: 'default',
            size: 'small'
          },
          { label: '确定', key: 'save', type: 'primary', size: 'small' }
        ]
      }).then(async (args) => {
        if (!args) return
        const { sliderForm, h } = args
        this.refresh = false
        this.$set(this.localValue, index, sliderForm)
        if (index == 0) {
          this.$emit('changeheight', h)
        }
        this.$nextTick(()=>{
          this.refresh = true
        })
      })
    },
    async handleClickAdd() {
      this.localValue = await this.localValue.concat({
        media_type: 'img',
        aiId:'',
        videoUrl: null,
        autoplay: false,
        loop: false,
        interact: 'reset',
        overlay: '',
        overlayWidth: 100,
        overlaybuttom: 0,
        overlayLeft: 0,
        slidercolor: '#000000',
        blockColor: '#000000',
        pic_type: '',
        imgUrl: '',
        mainTitle: '',
        imageName: '',
        hotData: [],
        overlayHotData: [],
        isCustomSlider: false,
        imageName: '',
        idx: this.localValue.length + 1
      })
    },
    async handleMove() {
      if (this.localValue[0].media_type == "video" && this.localValue[0].videoUrl) {
        getVideoAttribute(this.localValue[0].videoUrl, (h) => {
          this.$emit('changeheight', h)
        })
      } else {
        getImageAttribute(this.localValue[0].imgUrl, (h) => {
          this.$emit('changeheight', h)
        })
      }
    },
    async onChangeLocalData(e, pid, index) {
      this.$set(this.localValue[pid], 'data', e)
    },
    async onChangeLocalhotDate(e, pid, index) {
      let ele = cloneDeep(this.localValue[pid].hotData[index])
      Object.assign(ele, e)
      this.$set(this.localValue[pid].hotData, index, ele)
    },
    async onChangeLocaloverhotDate(e, pid, index) {
      let ele = cloneDeep(this.localValue[pid].overlayHotData[index])
      Object.assign(ele, e)
      this.$set(this.localValue[pid].overlayHotData, index, ele)
    },
    handleChangeType(index, e) {
      this.$set(this.localValue, index, {
        media_type: e,
        videoUrl: null,
        autoplay: false,
        loop: false,
        interact: 'reset',
        overlay: '',
        overlayWidth: 100,
        overlaybuttom: 0,
        overlayLeft: 0,
        slidercolor: '#000000',
        blockColor: '#000000',
        pic_type: '',
        imgUrl: '',
        mainTitle: '',
        imageName: '',
        hotData: [],
        overlayHotData: [],
        isCustomSlider: false,
        imageName:''
      })
    }
  }
}
</script>
