<template>
  <div :class="{
    'wgt-wallpaper': true,
    'padded': value.padded
  }" draggable="false">
    <div class="wgt-hd">
      <span class="sub-title" v-if="value.subtitle" :style={color:value.color}>{{ value.subtitle }}</span>
      <span class="title">{{ value.title }}</span>
    </div>
    <div class="wgt-wallpaper-content" ref="nav">
      <div class="carousel">
        <div v-for="item in value.data" :key="item.url" class="el-carousel-item">
          <SpImage :src="item.url" class="img" style="height: 560px;width: 270px;" fit="cover" />
          <div class="down-btn">下载</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: "WallPaper",
  wgtName: '壁纸下载',
  wgtDesc: '',
  wgtIcon: 'wgt-wall-paper',
  wgtType: 3,
  wgtTem:0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      isDown: false, // 是否按下鼠标
      startX: 0, // 鼠标起始位置
      scrollLeft: 0 // 滚动条位置
    };
  },
  mounted() {
    const currencyItemsRef = this.$refs.nav
    console.log('dom', currencyItemsRef);
    // 总结web端实现滑动，就是对鼠标按下、鼠标松开、鼠标移动事件进行监听
    currencyItemsRef.addEventListener('mousedown', (e) => {
      this.isDown = true;
      this.startX = e.pageX - currencyItemsRef.offsetLeft;
      this.scrollLeft = currencyItemsRef.scrollLeft;
    });
    currencyItemsRef.addEventListener('mouseup', (e) => {
      this.isDown = false;
    });
    currencyItemsRef.addEventListener('mouseenter', (e) => { this.isDown = false; });

    currencyItemsRef.addEventListener('mousemove', (e) => this.move(e));
  },
  methods: {
    move(e) {
      if (!this.isDown) return;
      const currencyItemsRef = this.$refs.nav
      e.preventDefault();
      const x = e.pageX - currencyItemsRef.offsetLeft;
      const walk = (x - this.startX) * 2; // 滑动距离
      if(walk > 0){
        currencyItemsRef.scrollLeft = this.scrollLeft - 285;
      }else{
        currencyItemsRef.scrollLeft = this.scrollLeft + 285;
      }
    }
  }
}

</script>


<style lang="scss" scoped>
.wgt-wallpaper {
  overflow: hidden;

  &.padded {
    padding: 10px 0;
  }

  .wgt-hd {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 25px;
    margin-bottom: 10px;

    .title {
      font-size: 20px;
      font-weight: normal;
      color: #000;
      line-height: 29px;
    }

    .sub-title {
      color: #666;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .wgt-wallpaper-content {
    height: 560px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }

    .carousel {
      display: flex;
      width: fit-content;
    }


  }

  .el-carousel-item {
    flex: 0 0 270px;
    height: 560px;
    margin-right: 15px;
    position: relative;

    &:first-child {
      margin-left: 25px;
    }

    .down-btn {
      position: absolute;
      bottom: 30px;
      left: 30px;
      right: 30px;
      background-color: #333;
      color: #fff;
      border-radius: 50px;
      font-size: 18px;
      text-align: center;
      line-height: 64px;
      height: 64px;
    }

    .img {
      border-radius: 14px;
    }
  }
}
</style>
