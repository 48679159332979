<style lang="scss" scoped>
.experiment {
  width: 100%;
}

.wgt-item {
  width: 100%;
  margin-top: 20px;
}

.coupon-item {
  display: flex;
}

.coupon {
  flex: 1;
  font-size: 14px;
}

.icon-trash-alt1 {
  color: #888;
  font-size: 13px;
}

.dialog-bd {
  display: flex;
  flex-wrap: wrap;
}

.item-wrap {
  text-align: center;
  margin: 0 20px 0 20px;
}

.wgt-icon {
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: 100%;

  &.active {
    border: 1px solid var(--themeColor);
  }
}

.wgt-name {
  color: #666;
  font-size: 11px;
}

.widget-item {
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  width:306px;
  padding: 5px;
  cursor: default;
}
</style>
<style lang="scss">
.dialog-wgt-list {
  .el-dialog__body {
    padding: 20px;
  }
}
</style>
<template>
  <div class="experiment">
    <el-select v-model="localValue.value" class="experiment" placeholder="选择实验" @change="changeExperiment">
      <el-option v-for="(item, index) in abList" :key="`experiment-item__${index}`" :label="item.title"
        :value="item.value">
      </el-option>
    </el-select>
    <el-button-group style="margin-top: 10px;">
      <el-button v-for="(btn, index) in groupList" :type="localValue.groupIndex == index ? 'primary' : 'default'"
        :key="`group-item__${index}`" @click="toggleGroupIndex(index)">
        {{ btn.title }}
      </el-button>
    </el-button-group>
    <!-- @onAddItem="handleAddTabs" -->
    <div class="wgt-item" v-if="localValue.value && localValue.list.length > 0">
      <CompTodoList v-model="localValue.list[localValue.groupIndex].children" is-edit btn-text="添加挂件" :max="20"
        @edit="onChangeCouponItem" @onAddItem="handleAddTabs" >
        <template slot="body" slot-scope="scope">
          <div class="widget-item">
            <span>{{ scope.data.wgtName }}</span>
            <span v-if="scope.data.name == 'customWgt'&&scope.data.pages_template_id">:【{{ scope.data.pages_template_id }}】{{
              scope.data.template_title
            }}</span>
          </div>
        </template>
      </CompTodoList>

      <el-dialog title="选择组件" class="dialog-wgt-list" :visible="dialogVisable" @close="cancelAction" append-to-body
        destroy-on-close>
        <div class="dialog-bd">
          <div class="item-wrap" v-for="(wgt, index) in wgtList" :key="`wgt-item__${index}`">
            <div :class="[
              'wgt-icon',
              wgt.wgtIcon,
              {
                'active': selected.indexOf(wgt.name) > -1
              }
            ]" :style="getWgtStyle(wgt)" @click="onSelectWgt(wgt)" />
            <div class="wgt-name">
              {{ wgt.wgtName }}
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAction">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { isEmpty } from '@/utils'
import { v4 as uuidv4 } from 'uuid'
import { gWgts } from '../../scene'
import EventBus from '@/utils/event-bus.js';
import axios from 'axios'
import { pickBy } from "@/utils";
import CompTodoList from '../../comps/comp-todoList'
export default {
  name: 'Experiment',
  components: { CompTodoList },
  props: {
    value: {
      type: Object
    },
    uuid: {
      type: String
    }
  },
  data() {
    return {
      localValue: { value: '', groupIndex: 0 },
      experiment: '',
      abList: [],
      dialogVisable: false,
      wgtList: [],
      selected: [],
    }
  },
  computed: {
    groupList() {
      if (isEmpty(this.value)) return []
      return this.value.list
    }
  },
  watch: {
    localValue: {
      handler(nVal, oVal) {
        const { groupIndex, list } = nVal
        this.$emit('changeChildren', list[groupIndex] ? list[groupIndex].children : [])
        this.$emit('input', nVal)
      },
      deep: true
    },
    uuid: {
      handler(nVal, oVal) {
        if (!isEmpty(this.value)) {
          this.localValue = cloneDeep(this.value)
        } else {
          const value = this.abList[0]
          value.list.map(item => item.children = [])
          this.localValue = { ...value, groupIndex: 0 }
        }
      },
      deep: true
    }
  },
  created() {
    this.selectedSet = new Set()
    const { scene = '1001' } = this.$route.query
    const { widgets: wgts } = gWgts[scene]
    Object.keys(wgts).forEach((key) => {
      console.log(wgts[key])
      if (wgts[key].wgtType != 0&&wgts[key].name != 'AbTest') {
        this.wgtList.push(wgts[key])
      }
    })
    // 查询AB实验组
    this.getABList()
  },
  methods: {
    async getABList() {
      const { experiments } = await this.$api.abtest.getExperimentList({ name: '' })
      let list = pickBy(experiments, { title: 'name', value: 'id', list: [] })
      const id = this.value ? this.value.value : list[0].value
      await this.getAbDetails(id, list)
      if (!isEmpty(this.value)) {
        this.localValue = this.value
      } else {
        const value = this.abList[0]
        value.list.map(item => item.children = [])
        this.localValue = { ...value, groupIndex: 0 }
      }
    },
    async getAbDetails(id, experiments = this.abList) {
      const { experiment } = await this.$api.abtest.getExperimentDetail({ id })
      this.abList = experiments?.map(item => {
        item.cname = experiment?.experiment_groups[0].group_variables[0].name
        if (item.value == experiment?.id) {
          item.list = pickBy(experiment.experiment_groups, {
            title: 'cname',
            value: 'group_variables[0].value',
          })
        }
        return item
      })
    },
    toggleGroupIndex(index) {
      this.$set(this.localValue, 'groupIndex', index)
    },

    async changeExperiment(e) {
      await this.getAbDetails(e)
      const fd = this.abList.find((item) => item.value == e)
      fd.list.map(item => item.children = [])
      this.localValue = {
        ...fd,
        groupIndex: 0,
      }

    },

    getWgtStyle(wgt) {
      if (wgt?.wgtIcon) {
        return {
          'background-image': `url(${require('@/assets/imgs/decorate/' + wgt.wgtIcon + '.png')})`
        }
      } else {
        return ''
      }
    },
    onSelectWgt(wgt) {
      if (this.selectedSet.has(wgt.name)) {
        this.selectedSet.delete(wgt.name)
      } else {
        this.selectedSet.add(wgt.name)
      }

      this.selected = Array.from(this.selectedSet.values())
    },
    async handleAddTabs(type) {
      this.dialogVisable = true
    },
    cancelAction() {
      this.dialogVisable = false
      this.selectedSet.clear()
      this.selected = []
    },
    confirmAction() {
      const { scene = '1001' } = this.$route.query
      const { widgets: wgts } = gWgts[scene]
      Object.keys(wgts).forEach((key) => {
        if (this.selectedSet.has(wgts[key].name)) {
          const wgtInitParams = this.cloneDefaultField(wgts[key])
          const { groupIndex } = this.localValue
          const localValue = cloneDeep(this.localValue)
          localValue.list[groupIndex].children.push({
            wgtName: wgts[key].wgtName,
            ...wgtInitParams
          })
          this.localValue = localValue
        }
      })

      this.dialogVisable = false
      this.selectedSet.clear()
      this.selected = []
    },
    cloneDefaultField(e) {
      const { wgtName, wgtDesc, config } = e
      const { setting, name } = JSON.parse(JSON.stringify(config))

      const compData = {
        uuid: uuidv4(),
        name,
        wgtName,
        wgtDesc
      }
      setting.forEach((item) => {
        compData[item.key] = item.value
      })
      return compData
    },
    async onChangeCouponItem({ item, index }) {
      if (item.name == 'customWgt') {
        const { data } = await this.$picker.customWgt({ multiple: false, data: [item.pages_template_id], isAb: true })
        if (!data) return
        const { groupIndex } = this.localValue
        const localValue = cloneDeep(this.localValue)
        localValue.list[groupIndex].children[index] = ({
          wgtName: '自定义模块',
          name: 'customWgt',
          pages_template_id: data[0].pages_template_id,
          style: 1,
          template_title: data[0].template_title,
          data: []
        })
        this.localValue = localValue
      } else {
        EventBus.$emit('active-wgt', item)
      }
    }

  }
}
</script>
