<style lang="scss">
.attr-picker-link {
  .btn-item {
    display: flex;
    align-items: center;

    .btn-linkpath {
      flex: 1;
    }

    .input-m {
      margin-left: 3px;
      width: 100px;
    }
  }

  .linktype-radio {
    margin-top: 10px;
    margin-bottom: 6px;

    .el-radio {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .el-radio__label {
      padding-left: 2px;
      font-size: 13px;
    }
  }

  .btn-linkpath {
    padding: 0 8px;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
    background-color: #fff;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    max-width: 200px;
    @include text-overflow();
  }

  input::-webkit-input-placeholder {
    font-size: 12px;
  }

  .el-input__inner {
    padding: 0 8px;
  }

  .h5-link {
    margin-top: 10px;
  }

  .video-list {
    display: flex;
  }
}
</style>
<template>
  <div class="attr-picker-link">
    <el-radio-group v-model="localValue.hotzoneType" class="linktype-radio">
      <el-radio label="link"> 自定义配置 </el-radio>
      <el-radio label="tabs"> Tabs切换 </el-radio>
      <el-radio label="goods"> 滑动到货架 </el-radio>
    </el-radio-group>
    <div class="btn-item" v-if="localValue.hotzoneType == 'link'">
      <div class="btn-linkpath" @click="onPickerPath">
        {{ getLabelName() }}
      </div>
      <el-select class="input-m" v-model="localValue.rule_id" placeholder="请选择" filterable remote clearable
        v-if="localValue && localValue.id == 'drawer-shop'" size="small" @change="handleRuleIdChange">
        <el-option v-for="item in list" :key="item.rule_id" :label="item.rule_title" :value="item.rule_id">
        </el-option>
      </el-select>
    </div>
    <div v-if="localValue.hotzoneType == 'tabs'">
      <el-radio-group v-model="localValue.hotzoneTypeIndex">
        <el-radio v-for="(i, x) in dataRes" :key="'typeIndex' + x" :label="x">Tabs{{ x + 1 }}</el-radio>
      </el-radio-group>
    </div>
    <div v-if="localValue.id === 'CopyLink'">
      <el-input v-model="localValue.CopyUrl" placeholder="请输入链接" class="h5-link" size="small" type="text" />
      <el-input v-model="localValue.CopyTitle" placeholder="请输入复制文案" class="h5-link" size="small" type="text" />
    </div>
    <div v-if="localValue.id === 'thirdpage'">
      <el-input v-model="localValue.linkTitle" placeholder="请输入跳转路径" class="h5-link" size="small" type="text" />
      <el-input v-model="localValue.linkAppid" placeholder="请输入对应小程序APPID" class="h5-link" size="small" type="text" />
      <el-form-item label="是否半屏跳转">
        <el-radio-group v-model="localValue.linkProgram">
          <el-radio label="1">否</el-radio>
          <el-radio label="2">是</el-radio>
        </el-radio-group>
      </el-form-item>
    </div>
    <div v-if="localValue.id === 'videopage'">

      <el-radio-group v-model="localValue.autoplay" class="linktype-radio">
        <el-radio :label="true"> 开启自动播放 </el-radio>
        <el-radio :label="false"> 关闭自动播放 </el-radio>
      </el-radio-group>
      <div class="video-list">
        <SpImagePicker v-model="localValue.video_cover" size="small" class="video-link" text="封面" />
        <SpVideoPicker type="object" v-model='localValue.video' size='small' class="video-link" />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapGetters } from "vuex";
import { isObject } from '@/utils'
import { LINK_PATH } from '@/consts'
import { getShowRuleList } from '@/api/template'
export default {
  name: 'AttrHotPicker',
  props: {
    value: {
      type: [Object, Array],
      default: () => { }
    },
    isShowH5Link: {
      // 不展示自定义链接label true：展示
      type: Boolean,
      default: true
    },
    isShowTabs: {
      type: Boolean,
      default: true
    },
    dataRes: {
      type: [Object, Array],
      default: () => { }
    }
  },
  data() {
    return {
      localValue: {
        linkPage: '',
        id: '',
        title: '',
        linkType: 0,
        linkUrl: '',
        video_cover: '',
        CopyUrl: "",
        CopyTitle: "",
        autoplay: false
      },
      list: []
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler: function (nVal, oVal) {
        const { heightPer, leftPer, topPer, widthPer } = this.value
        this.$emit('input', { ...nVal, heightPer, leftPer, topPer, widthPer })
        this.$emit('change', { ...nVal, heightPer, leftPer, topPer, widthPer })
      }
    }
  },

  computed: {
    ...mapGetters([
      'template_name',
      'wxapp_id'
    ])
  },
  created() {
    const localValue = Object.assign(this.localValue, this.value)
    const { video } = localValue
    localValue.video = isObject(video) ? video : {}
    this.localValue = cloneDeep(localValue)
    if (localValue.id == 'drawer-shop') {
      this.getList()
    }
  },
  methods: {
    async onPickerPath() {
      const { template_douyin = 0 } = this.$route.query
      const { linkPage, id } = this.localValue
      const res = await this.$picker.path({
        data: id,
        tab: linkPage,
        multiple: false,
        wxapp_id: this.wxapp_id,
        template_name: this.template_name,
        isDouyin: template_douyin == 1,
        isHotWords: false
      })
      this.localValue = {
        ...res,
        linkType: 0,
        linkUrl: '',
        video_cover: '',
        CopyUrl: "",
        CopyTitle: "",
        autoplay: false,
        hotzoneType: 'link'
      }
      if (res.id == "drawer-shop") {
        this.getList()
      } else {
        this.$set(this.localValue, 'rule_id', '')
      }
    },
    getLabelName() {
      const { linkPage, title, id } = this.localValue
      if (linkPage) {
        return `${LINK_PATH[linkPage]}：${id ? '[' + id + ']' : ''} ${title}`
      } else {
        return '选择路径'
      }
    },
    async getList() {
      const { template_douyin = 0 } = this.$route.query
      const res = await getShowRuleList({
        page: 1,
        page_size: -1,
        platform: template_douyin == 1 ? 'byte_mini' : 'wxapp',
      })
      this.list = [{ rule_id: '0', rule_title: '全部门店' }, ...res.data.data.list]
    },
    handleRuleIdChange(id) {
      let item = this.list.find(item => item.rule_id == id)
      this.localValue.distributor_header_image = item.distributor_header_image || ''
    }
  }
}
</script>
