import { pickBy } from '@/utils'

export default {
    name: 'baseacs',
    setting: [],
    transformIn: async () => { },
    transformOut: (v) => {
        return pickBy(v, {
            name: "name",
            base: (v) => {
                return {}
            },
            config: (v) => {
                return {}
            },
        });
    }
}
