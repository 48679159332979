<template>
    <div :class="{ 'wgt-baseacs': true }">
      <div class='base-acs'>
      <div class='base-acs__section'>
        <div class='base-acs__section-title'>设备信息</div>
        <div class='base-acs__section-content base-acs__section-content--flex'>
          <div class='base-acs__item'>
            <span class='base-acs__label'>机型</span>
            <span class='base-acs__value'>iphone</span>
          </div>
          <div class='base-acs__item'>
            <span class='base-acs__label'>序列号</span>
            <span class='base-acs__value'>9365HXL39</span>
          </div>
        </div>
      </div>

      <div class='base-acs__section'>
        <div class='base-acs__section-head'>
          <div class='base-acs__section-title base-acs__section-title--font'>保障信息</div>
          <div class='base-acs__status'>
            <div class='base-acs__status-dot active'>
              <span class='iconfont icon-xuanzhong-011'></span>
              <span class='base-acs__status-text'>服务生效中</span>
            </div>
            <span class='base-acs__status-date'>有效期至 2025.01.20</span>
          </div>
        </div>
        <div class='base-acs__section-content'>
          <div class='base-acs__item'>
            <span class='base-acs__label'>协议编号</span>
            <span class='base-acs__value'>197486022344</span>
          </div>
          <div class='base-acs__item'>
            <span class='base-acs__label'>购买门店</span>
            <span class='base-acs__value'>漕河泾印象城店</span>
          </div>
          <div class='base-acs__item'>
            <span class='base-acs__label'>购买平台</span>
            <span class='base-acs__value'>微信小程序</span>
          </div>
          <div class='base-acs__item'>
            <span class='base-acs__label'>维修情况</span>
            <span class='base-acs__value'>无维修记录</span>
          </div>
        </div>
        <div class='base-acs__section-border'></div>
      </div>
    </div>
    </div>
</template>

<script>
import config from './config'
export default {
    name: 'baseacs',
    wgtName: 'ACS基础信息',
    wgtDesc: '',
    wgtIcon: 'wgt-user-container',
    wgtType: 3,
    wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
    config: config,
    props: {
        value: [Object, Array]
    },
    data() {
        return {}
    },
    created() { },
    methods: {},
    computed: {
        memberInfoStyle() {
            const { backgroundImg } = this.value
            return {
                backgroundImage: `url(${backgroundImg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center',
            }
        },
        contentStyle() {
            const { padded: { paddedt, paddedb, paddedl, paddedr } } = this.value
            return {
                paddingTop: `${paddedt}px`,
                paddingBottom: `${paddedb}px`,
                paddingLeft: `${paddedl}px`,
                paddingRight: `${paddedr}px`,
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.base-acs {
  padding: 18px;

  &__section {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
      background: #fff;
      border-top-left-radius: 22px;
      border-top-right-radius: 22px;
    }
  }

  &__section-title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 25px;
    color: #1D1D1F;
    margin-bottom: 12px;
  }

  &__section-title--font {
    font-size: 15px;
    margin-bottom: 27px;
  }


  &__section-content {
    background: #fff;
    padding: 8px 16px;
  }

  &__section-content--flex{
    border-radius: 22px;
  }

  &__section-head {
    background: #F9F9FB;
    padding: 12px 16px 16px;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
  }

  &__section-border{
    border-bottom: 1px solid #ECECEE;
    padding-top: 12px;
    margin: 0 16px;
  }

  &__item {
    height: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    color: #1D1D1F;
    font-size: 12px;
  }

  &__value {
    color: #333;
    font-size: 14px;
  }

  &__status {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-dot {
      .iconfont{
        font-size: 13px;
        color: #C9C9CB;
        margin-right: 5px;
      }
      .base-acs__status-text{
        font-size: 12px;
        color: #1D1D1F;
      }
    }

    .active {
      .iconfont{
        font-size: 15px;
        color: #27A33F;
      }
      .base-acs__status-text{
        color: #27A33F;
        font-size: 15px;
      }
    }

    &-date {
      color: #1D1D1F;
      font-size: 12px;
    }
  }
}
</style>