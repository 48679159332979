<template>
  <div class="attr-select">
    <el-select v-model="localValue.rule_id" @change="handleRuleIdChange">
      <el-option v-for="item in selectShopOptions" :key="+item.rule_id" :label="item.rule_title" :value="+item.rule_id"/>
    </el-select>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { getShowRuleList } from '@/api/template'
export default {
  name: 'AttrSelect',
  props: {
    value: {
      type: Object,
    },
    uuid:{
      type:String
    }
  },
  data(){
    return {
      localValue: {},
      selectShopOptions: []
    }
  },
  methods: {
    async getShopList () {
      const res = await getShowRuleList({page: 1, page_size: -1, platform: 'wxapp'})
      const data = [
        { rule_id: 0, rule_title: '全部门店' },
        ...res.data.data.list
      ]
      this.selectShopOptions = data
    },
    handleRuleIdChange(id) {
      let item = this.selectShopOptions.find(item => item.rule_id == id)
      this.localValue.distributor_header_image = item.distributor_header_image || ''
      console.log("🚢 ~ 当前打印的内容 ~ localValue:222222222222222222222", this.localValue);

      this.$emit('input', this.localValue)
    }
  },
  watch: {
    localValue (nVal) {
      console.log("🚢 ~ 当前打印的内容 ~ nVal:11111111111111111", nVal);
      this.$emit('input', nVal)
    },
    uuid: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true,
      deep: true
    }
  },
  created(){
    this.getShopList()
  }
}
</script>
